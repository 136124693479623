export function handleAppActionLogin() {
  // 登录
  if (window.ReactNativeWebView) {
    window.ReactNativeWebView.postMessage(
      JSON.stringify({ action: "startLoginPage" })
    );
  } else {
    window.webkit.messageHandlers.startLoginPage.postMessage({});
  }
}
export function handleAppActionCloseWebView() {
  // 登录
  if (window.ReactNativeWebView) {
    window.ReactNativeWebView.postMessage(
      JSON.stringify({ action: "closeWebView" })
    );
  } else {
    window.webkit.messageHandlers.closeWebView.postMessage({});
  }
}
export function handleAppActionBackToHome() {
  // 返回首页
  if (window.ReactNativeWebView) {
    window.ReactNativeWebView.postMessage(
      JSON.stringify({ action: "backToHomePage" })
    );
  } else {
    window.webkit.messageHandlers.backToHomePage.postMessage({});
  }
}
export function handleAppActionShare(content) {
  //分享
  if (window.ReactNativeWebView) {
    window.ReactNativeWebView.postMessage(
      JSON.stringify({
        action: "receiveWebClick",
        params: { ShareCodeContent: content },
      })
    );
  } else {
    window.webkit.messageHandlers.receiveWebClick.postMessage({
      ShareCodeContent: content,
    });
  }
}
export function handleAppActionPay(type) {
  //支付
  if (window.ReactNativeWebView) {
    window.ReactNativeWebView.postMessage(
      JSON.stringify({
        action: "payStatusCallBack",
        params: { type },
      })
    );
  } else {
    window.webkit.messageHandlers.payStatusCallBack.postMessage({ type });
  }
}
export function handleAppActionTopup(type) {
  //充值
  if (window.ReactNativeWebView) {
    window.ReactNativeWebView.postMessage(
      JSON.stringify({
        action: "topupStatusCallBack",
        params: { type },
      })
    );
  } else {
    window.webkit.messageHandlers.topupStatusCallBack.postMessage({ type });
  }
}
export function handleAppActionSetTitle(title) {
  //设置标题
  if (window.ReactNativeWebView) {
    window.ReactNativeWebView.postMessage(
      JSON.stringify({
        action: "setWebTitle",
        params: { title },
      })
    );
  } else {
    window.webkit.messageHandlers.setWebTitle.postMessage({title});
  }
}
export function handleAppActionProduct(product_id) {
  //跳转产品详情页
  if (window.ReactNativeWebView) {
    window.ReactNativeWebView.postMessage(
      JSON.stringify({
        action: "pushDataDetail",
        params: { product_id },
      })
    );
  } else {
    window.webkit.messageHandlers.pushDataDetail.postMessage({"Id":product_id});
  }
}
